@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.App {
  background-color: #fffff8;
  height: 100vh;
  width: 100vw;
  color: #fff;
  font-family: et-book, Palatino, "Palatino Linotype", "Palatino LT STD",
    "Book Antiqua", Georgia, serif;
  color: #111111;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
    width: 768px;
    height: 80%;
    background-color: #fffff8;
}


.head {
  width: 768px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}


.title {
  font-weight: 400;
  margin-top: 4rem;
  margin-left: 0rem;
  margin-bottom: 1rem;
  font-size: 3.0rem;
  line-height: 1;
  display: inline-block;
}

.icon {
  font-weight: 200;
  margin-top: 4rem;
  margin-left: 0rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1;
}

.subtilte {
  font-weight: 400;
  margin-bottom: 1.5rem;
  margin-left: 2rem;
  font-size: 1rem;
  line-height: 1.5;
}

.image {
  margin-top: 4rem;
  height: 3rem;
  borderradius: "full";
}

.mainbody {
  margin-left: 2rem;
  margin-top: 4rem;
}

.sectionTitle {
  font-weight: 600;
  margin-top: 4rem;
  margin-left: 0rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1;
}

@media screen and (max-width: 768px) {
.main {
    max-width: 768px;
    height: 80%;
    background-color: #fffff8;
  }
}

@media screen and (max-width: 767px) {
.head {
    max-width: 768px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
